import React from "react";
import { Container, Row, Col } from "reactstrap";

function Proyecto() {
  
  
  return (
    <>
      <div id="proyecto" className="section section-feature cd-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto left pb-5" md="8">
                <h2 className="title">Proyecto Golfo Corcovado</h2>
                <h5 className="description">
                  Golfo Corcovado, es un proyecto de campo que cuenta con excelente accesibilidad.
                </h5>
                <h5 className="description">
                  Puede acceder luego de un breve paseo en lancha, que le permitirá disfrutar de las
                bellezas naturales de la zona y sus diversos paisajes, ubicado a orillas del puerto Auchemó,
                busca el equilibrio perfecto entre el habitar del hombre con la naturaleza.
                </h5>
                <h5 className="description">
                  La tranquilidad y color de sus aguas, lo hace ser un lugar soñado para los
                navegantes y quienes buscan cercanía con la vida al aire libre. Su posición destaca con la
                gran isla de Chiloé por el oeste, la carretera austral por el este y el mar por el norte.
                  
                </h5>
                <h5 className="description">
                  Más de 6700 mts de orilla de mar, gran potencial de ecoturismo, además de contar
                con estratégico acceso a grandes cuencas hidrográficas, bosque nativo, cualidades que
                evidencian la gran plusvalía del proyecto, que se ubica a sólo a 40 minutos de Chaitén,
                pueblo con amplia infraestructura y servicios.
                </h5>
                <h5 className="description">
                  La Bahía de Auchemó se localiza en la Región de Los Lagos y es conocida por contar
                con una gran población de delfines y lobos marinos. Un grupo de islas, ríos y volcanes
                forman parte de las increíbles vistas que el territorio ofrece.
                </h5>
              </Col>
            </Row>
        </Container>
      </div>
    </>
  );
}
export default Proyecto;
