import React from "react";
import { Container, Row, Col } from "reactstrap";
import Map from './map'

function Ubicacion() {
  return (
    <>
      <div className="section section-feature cd-section" id="ubicacion">
        <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Ubicación</h2>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col md="6">
            <Map/>
          </Col>
          <Col className="offset-1" md="4">
            <div className="info info-horizontal mt-0 pt-sm-4 pt-md-0">
              <div className="icon icon-info">
                <i aria-hidden={true} className="nc-icon icon-square-pin" />
              </div>
              <div className="description mr-0">
                <h5 className="info-title">
                  Golfo Corcovado, se ubica entre el Parque Pumalín y el Parque Nacional Corcovado, 
                  dos de los parques más grandes de la Patagonia. 
                  Brinda locaciones como el Río Yelcho, playa Santa Bárbara, 
                  El Amarillo e increíbles lugares que te permitirán realizar todo tipo de actividades al aire libre.
                </h5>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
    </>
  );
}

export default Ubicacion;
