import React from 'react';

function LogoInstagram({width, height}) {

	return (
		<svg  height={height} width={width} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g>
                <defs>
                    <radialGradient id="svg-radial-gradient-insta" cx="-155.913" cy="-943.442" gradientTransform="matrix(0, -1.983, -1.844, 0, -1725.456, -260.866)" gradientUnits="userSpaceOnUse" r="20.996">
                        <stop offset="0" stopColor="#fd5"/>
                        <stop offset="0.1" stopColor="#fd5"/>
                        <stop offset="0.5" stopColor="#ff543e"/>
                        <stop offset="1" stopColor="#c837ab"/>
                    </radialGradient>
                    <radialGradient id="svg-radial-gradient-insta-2" cx="805.728" cy="-581.085" gradientTransform="matrix(0.174, 0.869, 3.582, -0.717, 1937.148, -1110.973)" gradientUnits="userSpaceOnUse" r="20.996">
                        <stop offset="0" stopColor="#3771c8"/>
                        <stop offset="0.128" stopColor="#3771c8"/>
                        <stop offset="1" stopColor="#60f" stopOpacity="0"/>
                    </radialGradient>
                </defs>
                <path d="M24.007,3c-8.767,0-11.332.009-11.83.05A10.409,10.409,0,0,0,8.039,4.091,8.371,8.371,0,0,0,5.626,5.858a9.014,9.014,0,0,0-2.433,5.108c-.142.983-.183,1.183-.192,6.2,0,1.673,0,3.874,0,6.826,0,8.762.01,11.325.052,11.822a10.553,10.553,0,0,0,1,4.058,8.849,8.849,0,0,0,5.734,4.685,14.885,14.885,0,0,0,3.049.4c.52.023,5.821.039,11.125.039s10.608-.006,11.115-.032a14.336,14.336,0,0,0,3.159-.414,8.792,8.792,0,0,0,5.734-4.695,10.38,10.38,0,0,0,.99-3.979c.028-.362.04-6.132.04-11.894s-.013-11.522-.041-11.884a10.284,10.284,0,0,0-1.01-4.019,8.335,8.335,0,0,0-1.8-2.463,9.059,9.059,0,0,0-5.113-2.427c-.981-.142-1.176-.184-6.2-.193Z" fill="url(#svg-radial-gradient-insta)"/>
                <path d="M24.007,3c-8.767,0-11.332.009-11.83.05A10.409,10.409,0,0,0,8.039,4.091,8.371,8.371,0,0,0,5.626,5.858a9.014,9.014,0,0,0-2.433,5.108c-.142.983-.183,1.183-.192,6.2,0,1.673,0,3.874,0,6.826,0,8.762.01,11.325.052,11.822a10.553,10.553,0,0,0,1,4.058,8.849,8.849,0,0,0,5.734,4.685,14.885,14.885,0,0,0,3.049.4c.52.023,5.821.039,11.125.039s10.608-.006,11.115-.032a14.336,14.336,0,0,0,3.159-.414,8.792,8.792,0,0,0,5.734-4.695,10.38,10.38,0,0,0,.99-3.979c.028-.362.04-6.132.04-11.894s-.013-11.522-.041-11.884a10.284,10.284,0,0,0-1.01-4.019,8.335,8.335,0,0,0-1.8-2.463,9.059,9.059,0,0,0-5.113-2.427c-.981-.142-1.176-.184-6.2-.193Z" fill="url(#svg-radial-gradient-insta-2)"/>
                <path d="M24,8.493c-4.211,0-4.739.018-6.393.093a11.37,11.37,0,0,0-3.763.721,7.927,7.927,0,0,0-4.536,4.535,11.337,11.337,0,0,0-.721,3.765c-.074,1.654-.093,2.183-.093,6.4s.019,4.739.094,6.393a11.4,11.4,0,0,0,.72,3.764A7.932,7.932,0,0,0,13.839,38.7a11.4,11.4,0,0,0,3.764.72c1.654.075,2.182.094,6.392.094s4.738-.019,6.392-.094a11.412,11.412,0,0,0,3.765-.72,7.942,7.942,0,0,0,4.534-4.537,11.518,11.518,0,0,0,.721-3.765c.074-1.654.093-2.181.093-6.393s-.019-4.741-.093-6.395a11.506,11.506,0,0,0-.721-3.764,7.927,7.927,0,0,0-4.535-4.536,11.406,11.406,0,0,0-3.766-.721c-1.654-.075-2.181-.093-6.393-.093Zm-1.391,2.8H24c4.14,0,4.631.014,6.265.089a8.583,8.583,0,0,1,2.879.534,5.138,5.138,0,0,1,2.942,2.943,8.583,8.583,0,0,1,.534,2.879c.074,1.635.091,2.126.091,6.265s-.017,4.63-.091,6.265a8.583,8.583,0,0,1-.534,2.879,5.14,5.14,0,0,1-2.942,2.942,8.557,8.557,0,0,1-2.879.534c-1.634.074-2.125.09-6.265.09s-4.631-.016-6.265-.09a8.582,8.582,0,0,1-2.879-.535,5.138,5.138,0,0,1-2.943-2.942,8.532,8.532,0,0,1-.534-2.879c-.075-1.635-.09-2.126-.09-6.268s.015-4.63.09-6.265a8.563,8.563,0,0,1,.534-2.879,5.133,5.133,0,0,1,2.943-2.944,8.525,8.525,0,0,1,2.879-.535c1.43-.065,1.985-.084,4.874-.087Zm9.667,2.575a1.861,1.861,0,1,0,1.861,1.86,1.86,1.86,0,0,0-1.861-1.86ZM24,16.037A7.964,7.964,0,1,0,31.958,24,7.964,7.964,0,0,0,24,16.037Zm0,2.8A5.17,5.17,0,1,1,18.829,24,5.169,5.169,0,0,1,24,18.832Z" fill="#fff"/>
            </g>
        </svg>
	);
};

export default LogoInstagram;