import ImagesGrid from 'components/ImagesGrid'
import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Masterplan = () => {
    return(
        <div>
            <div className="section text-center" id="etapa1">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">Golfo Corcovado - Etapa 1</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="embed-responsive-vt">
                                <iframe className="embed-responsive-item-vt" title='masterplan' src="https://www.lanube360.com/auchamo/"></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="pt-4">
            {/* <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Atributos I Etapa</h2>
              </Col>
            </Row> */}
            <Row>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-wifi" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Conectividad</h4>
                    <h5><small>Cuenta con antena de telecomunicaciones.</small></h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-rowing" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Deportes Outdoor</h4>
                    <h5><small>Factibilidad de realizar deportes como pesca, kayak, navegación y trekking</small></h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-mountain" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Miradores</h4>
                    <h5>
                      <small>
                        Sendero principal construido por los mismos artesanos del parque Pumalín que da acceso
                        al Mirador Caleuche y ofrece extraordinarias vistas panorámicas.
                      </small>
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-beach-umbrella" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Playas</h4>
                    <h5>
                      <small>
                        Playa común en el proyecto.
                      </small>
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Ubicación</h4>
                    <h5>
                      <small>
                        A sólo 4,5 horas de Santiago.
                      </small>
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-property-agreement" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Rol propio</h4>
                    <h5>
                      <small>
                        Terrenos con Rol Propio.
                      </small></h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

            </div>
            <Container>
              <ImagesGrid/>
            </Container>
            <div className="section text-center" id="etapa2">
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto text-center" md="8">
                            <h2 className="title">Golfo Corcovado - Etapa 2</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="embed-responsive-vt">
                                <iframe className="embed-responsive-item-vt" title='masterplan' src="https://www.lanube360.com/auchamo-2/"></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>



          <Container className="pt-4">
            {/* <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Atributos II Etapa</h2>
              </Col>
            </Row> */}
            <Row>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-wifi" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Conectividad</h4>
                    <h5><small className='text-muted'>Cuenta con antena de telecomunicaciones.</small></h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-rowing" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Deportes Outdoor</h4>
                    <h5><small>Factibilidad de realizar deportes como pesca, kayak, navegación y trekking.</small></h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-mountain" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Vistas Panorámicas</h4>
                    <h5><small>Cada terreno cuenta con vistas panorámicas de la Bahía de Auchemó</small></h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-boat-small-02" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Islas y playas</h4>
                    <h5>
                      <small>
                        Todos los campos cuentan con orilla de mar y  acceso a su terreno por vía marítima
                      </small></h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-square-pin" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Ubicación</h4>
                    <h5>
                      <small>
                        A sólo 4,5 horas de Santiago.
                      </small>
                    </h5>
                  </div>
                </div>
              </Col>
              <Col md="2">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon icon-property-agreement" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Rol propio</h4>
                    <h5>
                      <small>
                        Terrenos con Rol Propio.
                      </small>
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
            </div>
        </div>
    )
}
export default Masterplan