import React from 'react'
import Img1 from '../../assets/images/images-grid/img1.jpg'
import Img2 from '../../assets/images/images-grid/img2.jpg'
import Img3 from '../../assets/images/images-grid/img3.jpg'

function ImagesGrid() {
  return (
    <div style={{
        display:'grid',
        height:'auto',
        width:'100%',
        gap:'7px',
        gridTemplate:'[top] 1fr [bottom] / [izq] repeat(3,1fr) [der]',
        paddingTop:'20px'
    }}>
        <img style={{width:'100%', backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} src={Img1} alt="img1"/>
        <img style={{width:'100%', backgroundSize:'cover',  backgroundPosition:'center', backgroundRepeat:'no-repeat'}} src={Img2} alt="img2"/>
        <img style={{width:'100%', backgroundSize:'cover',  backgroundPosition:'center', backgroundRepeat:'no-repeat'}} src={Img3} alt="img3"/>
        
    </div>
  )
}

export default ImagesGrid