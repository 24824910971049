import React from 'react'
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const containerStyle = {
	width: "100%",
	height: "520px",
	maxHeight: "520px",
};
const etapa1 = { lng:-72.832392, lat:-43.023784}
const etapa2 = { lng:-72.804981,lat:-43.025124 }

const center = { lng:-72.819296,lat:-43.025211 };

function Map() {
	    		
	const options = {
		fillColor: "lightblue",
		fillOpacity: 0.2,
		strokeColor: "red",
		strokeOpacity: 1,
		strokeWeight: 2,
		clickable: true,
		draggable: false,
		editable: false,
		geodesic: false,
		color:'white',
		zIndex: 10
	  }
	
	const onLoad = () => console.log('onload!')
	
	return (
		<LoadScript googleMapsApiKey="AIzaSyBX-fT0cZLTKydQN_HFfeCfHKq_Um_4cSQ">
			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={13}
				mapTypeId={"hybrid"}
				zIndex="1"

			>
				<Marker
					label={ {text:'Etapa1', color:'white' }}
					onLoad={onLoad}
					position={etapa1}
					options={options}
					
				/>
				<Marker
					label={ {text:'Etapa2', color:'white' }}
					onLoad={onLoad}
					position={etapa2}
					options={options}
					
				/>
			</GoogleMap>
		</LoadScript>
	);
}
export default Map